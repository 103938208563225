import WithBackgroundImagePartial from '@/pages/lib/partials/WithBackgroundImagePartial/WithBackgroundImagePartial.vue'

export default {
  props: {
  },
  components: {
    WithBackgroundImagePartial
  },
  computed: {
    items () {
      return [
        {
          itemTitle: this.i18nSection('featuresTitle1'),
          itemText: this.i18nSection('featuresText1'),
          titleAsH2: true
        },
        {
          itemTitle: this.i18nSection('featuresTitle2'),
          itemText: this.i18nSection('featuresText2'),
          titleAsH2: true
        }
      ]
    }
  }
}
