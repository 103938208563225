export default {
  props: {
    min: {
      type: [Number, String],
      default: 0
    },
    max: {
      type: [Number, String],
      default: 100
    },
    value: [Number, String]
  },
  methods: {
    onInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
