import { mapState, mapActions } from 'vuex'
import { ReferralLink } from 'src/models'

import TxSlider from '@/components/lib/TxSlider/TxSlider.vue'

import TxButton from '@/components/lib/TxButton/TxButton.vue'
import SelectItem from '@/components/lib/SelectItem/SelectItem.vue'
import TxInlineSvg from '@/components/lib/TxInlineSvg/TxInlineSvg.vue'

import TwoColumnHeaderPartial from '../TwoColumnHeaderPartial/TwoColumnHeaderPartial.vue'

export default {
  components: {
    TwoColumnHeaderPartial,
    TxButton,
    TxSlider,
    SelectItem,
    TxInlineSvg
  },
  props: {
    referralLink: ReferralLink
  },
  data () {
    return {
      sliderValue: 10,
      maxSliderValue: 70,
      isBusy: false
    }
  },
  computed: {
    ...mapState({
      passport: state => state.passport?.user
    }),
    headerContent () {
      return {
        title: '',
        description: this.i18nSection('partnerPageSubTitle'),
        subtitle: this.i18nSection('technologypagePromoSubTitle')
      }
    },
    level0 () {
      return this.maxSliderValue - this.sliderValue
    },
    level1 () {
      return this.sliderValue
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    async handleBecomePartner () {
      this.isBusy = true
      if (this.passport) {
        this.openPrivateUrl('private/user/partner-account')
      } else {
        await this.authInitiate({ mode: 'signup' })
      }
    }
  }
}
