import NavBar from '@/pages/lib/sections/NavBar/NavBar.vue'
import PublicFooter from '@/pages/lib/sections/PublicFooter/PublicFooter.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

import HeaderSection from './HeaderSection/HeaderSection.vue'
import FeaturesSection from './FeaturesSection/FeaturesSection.vue'
import HowToSection from './HowToSection/HowToSection.vue'

export default {
  components: {
    NavBar,
    PublicFooter,
    TxButton,
    HeaderSection,
    FeaturesSection,
    HowToSection
  },
  computed: {
  }
}
