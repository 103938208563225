
export default {
  props: {
    item: Object // num, title, text,
  },
  methods: {
    extractContent (content) {
      if (content === 'undefined.title') {
        return ''
      }
      return content
    }
  }
}
