import VueTypes from 'vue-types'

export default {
  props: {
    items: Array,
    initialValue: { String, Object },
    getKeyField: {
      type: Function,
      default: item => item
    },
    getValueField: {
      type: Function,
      default: item => item
    },
    label: String,
    disabled: Boolean,
    theme: VueTypes.oneOf(['normal', 'dark', 'light']).def('normal'),
    direction: VueTypes.oneOf(['top', 'right', 'bottom', 'left']).def('bottom'),
    maxHeight: {
      type: [String, Number],
      default: 204
    },
    width: {
      type: [String, Number],
      default: 138
    },
    attach: {
      type: Boolean,
      default: true
    },
    iconMode: Boolean
  },
  data () {
    return {
      value: '',
      isSelectBoxOpen: false,
      position: {
        top: '0',
        left: '0'
      },
      selectBoxElement: null,
      parentElement: null
    }
  },
  methods: {
    toggleSelectBox () {
      if (!this.isSelectBoxOpen) {
        if (!this.attach) {
          if (!this.parentElement.contains(this.selectBoxElement)) {
            this.parentElement.appendChild(this.selectBoxElement)
            this.$nextTick(() => {
              this.calculateSelectBoxPosition()
              this.isSelectBoxOpen = true
            })
          }
        } else {
          this.$nextTick(() => {
            this.calculateSelectBoxPositionAttach()
            this.isSelectBoxOpen = true
          })
        }
      } else {
        this.removeSelectBox()
      }
    },
    removeSelectBox (e) {
      this.isSelectBoxOpen = false
      if (!this.attach) {
        if (this.parentElement.contains(this.selectBoxElement)) {
          this.parentElement.removeChild(this.selectBoxElement)
        }
      }
    },
    selectItem (value) {
      this.value = value
      this.removeSelectBox()
      this.$emit('input', value)
      this.$emit('change', value)
    },
    calculateSelectBoxPosition () {
      const position = this.parentElement.getBoundingClientRect()
      const offset = 0
      switch (this.direction) {
        case 'top':
          this.position.top = position.top - this.selectBoxElement.offsetHeight
          this.position.left = position.left

          break
        case 'left':
          this.position.top = position.top - (this.selectBoxElement.offsetHeight / 2)
          this.position.left = position.left - this.selectBoxElement.offsetWidth - offset

          break
        case 'right':
          this.position.top = position.top - (this.selectBoxElement.offsetHeight / 2)
          this.position.left = position.right + offset

          break

        case 'bottom':
        default:
          this.position.top = position.bottom - 15
          this.position.left = position.left

          break
      }
    },
    calculateSelectBoxPositionAttach () {
      const offset = 20
      switch (this.direction) {
        case 'top':
          this.position.top = -this.parentElement.offsetHeight - offset
          this.position.left = 0

          break
        case 'left':
          this.position.top = 0
          this.position.left = -this.parentElement.offsetWidth - offset

          break
        case 'right':
          this.position.top = 0
          this.position.left = this.parentElement.offsetWidth + offset

          break
        case 'bottom':
        default:
          this.position.top = 15 + offset
          this.position.left = this.iconMode
            ? -(this.width / 2 - 17)
            : 0

          break
      }
    }
  },
  mounted () {
    if (this.initialValue) {
      this.value = this.getValueField(this.initialValue)
    }
    this.selectBoxElement = this.$refs.selectBox
    this.parentElement = this.$refs.rootSelectItem
    if (!this.attach) {
      this.$nextTick(() => {
        this.parentElement.removeChild(this.selectBoxElement)
      })
    }
    function addListenerMulti (element, eventNames, listener) {
      const events = eventNames.split(' ')
      for (let i = 0, iLen = events.length; i < iLen; i++) {
        element.addEventListener(events[i], listener, false)
      }
    }
    addListenerMulti(window, 'click touchcancel', this.removeSelectBox)
  },
  beforeDestroy () {
    this.removeSelectBox()
    window.removeEventListener('click', this.removeSelectBox)
  }
}
