import { render, staticRenderFns } from "./PartnerLanding.htm?vue&type=template&id=37199010&scoped=true&"
import script from "./PartnerLanding.js?vue&type=script&lang=js&"
export * from "./PartnerLanding.js?vue&type=script&lang=js&"
import style0 from "./PartnerLanding.scss?vue&type=style&index=0&id=37199010&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37199010",
  null
  
)

export default component.exports