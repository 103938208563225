var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"rootSelectItem",staticClass:"root-select-item"},[_c('div',{staticClass:"control-container",class:{
      'not-empty': _vm.value,
      'active-select-box': _vm.isSelectBoxOpen,
      'control-container-dark-theme': _vm.theme === 'dark',
      'control-container-light-theme': _vm.theme === 'light',
      'icon-mode': _vm.iconMode
    },attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();!_vm.disabled && _vm.toggleSelectBox()}}},[(!_vm.iconMode)?_c('label',[_vm._v(_vm._s(_vm.label))]):_vm._e(),(!_vm.iconMode)?_c('span',{staticClass:"active-label"},[_vm._v(_vm._s(_vm.value ? _vm.getValueField(_vm.value) : ''))]):_vm._e(),(!_vm.isSelectBoxOpen)?_c('svgicon',{staticClass:"icon-down",attrs:{"width":"24","height":"24","name":"arrow-down"}}):_c('svgicon',{staticClass:"icon-up",attrs:{"width":"24","height":"24","name":"arrow-up"}})],1),(_vm.isSelectBoxOpen && _vm.attach)?_c('div',{ref:"selectBox",staticClass:"select-box",class:{
      'select-box-active': _vm.isSelectBoxOpen,
      'select-box-dark-theme': _vm.theme === 'dark',
      'select-box-light-theme': _vm.theme === 'light'
    },style:({
      position: _vm.attach ? 'absolute' : 'fixed',
      top: ((_vm.position.top) + "px"),
      left: ((_vm.position.left) + "px"),
      'min-width': (_vm.width + "px"),
      'max-height': (_vm.maxHeight + "px")
    })},_vm._l((_vm.items),function(item){return _c('div',{key:_vm.getKeyField(item),staticClass:"select-item",class:{
        'active': _vm.value && _vm.getKeyField(_vm.value) === _vm.getKeyField(item)
      },on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(item)}}},[_vm._t("default",[_c('span',{staticClass:"text-content"},[_vm._v(_vm._s(_vm.getValueField(item)))])],{"item":item})],2)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }