import { mapGetters, mapState } from 'vuex'

import TxButton from '@/components/lib/TxButton/TxButton.vue'
import ScrollItemsPartial from '@/pages/lib/partials/ScrollItemsPartial/ScrollItemsPartial.vue'
import HowToItem from './HowToItem/HowToItem.vue'

export default {
  props: {
  },
  components: {
    TxButton,
    HowToItem,
    ScrollItemsPartial
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
    }),
    content () {
      return {
        title: this.i18nSection('htsHeader'),
        asH2: true
      }
    },
    howTo () {
      return [
        {
          num: 1,
          title: this.i18nSection('htsTitle1'),
          text: this.i18nSection('htsText1')
        },
        {
          num: 2,
          title: this.i18nSection('htsTitle2'),
          text: this.i18nSection('htsText2')
        },
        {
          num: 3,
          title: this.i18nSection('htsTitle3'),
          text: this.i18nSection('htsText3')
        }
      ]
    }
  },
  methods: {
    scrollItems () {
      const slider = document.querySelector('.slider')
      let isDown = false
      let startX
      let scrollLeft

      slider.addEventListener('mousedown', (e) => {
        isDown = true
        slider.classList.add('active')
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) {
          return
        }
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 3 // scroll-fast
        slider.scrollLeft = scrollLeft - walk
      })
    }
  }
}
